import React from 'react';
import logo from './logo.svg';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src="vault-boy-white.png"></img>
        <h1>phazer</h1>
        <h5>VISUALS</h5>
      </header>
    </div>
  );
}

export default App;
